//点击确认 》补签   补签文件，暂时不上
<template>
  <div class="supplementSign">
    <!-- 返回 -->
    <backtitle lable="签约管理" />
    <div class="signBackground">
      <!-- 头部汇总 -->
      <sign-header :summaryData="summaryData"></sign-header>
      <div class="warging" v-if="role === '20'"><i class="el-icon-warning"/>请确认供应商以下文件是否可由稳诚一方协助盖章：</div>
      <!-- 表格头部 -->
      <div class="headeritle">
        <sign-title :label="textLabel" :amount="amount">
          <template v-slot:btn>
            <base-button label="批量下载" @click="downAll"></base-button>
            <!-- <base-button
              v-if="  === '20'"
              label="文件信息"
              @click="showInfo"
            ></base-button> -->
              <base-button
              v-if="sealType === '1'"
              label="文件盖章序"
              @click="showSealOrder"
            ></base-button>
          </template>
        </sign-title>
      </div>
      <!-- 表格内容 -->
      <base-table
        class="main-page-table"
        :columns="columns"
        :showPage="false"
        :tableAttrs="{
          data: tableData,
          stripe: true,
        }"
        :dataSource.sync="tableData"
        :api="api"
        :loadCount="loadCount"
        :webPage="false"
      >
        >
        <template slot="code" slot-scope="scope">
          {{scope.$index+1}}
        </template>
        <template slot="action" slot-scope="scope">
          <icon-button
            @click="showPreview(scope.row)"
            content="预览"
            icon="iconfont iconyulan"
          />
        </template>
      </base-table>
    </div>
    <!-- 底部按钮 -->
    <!-- 暂时只有一个 -->
    <!-- <div class="footer">
      <base-button
        :label="sealType==='0'?'鲜章盖章下载':'自动发送签章'"
        @click="confirmSeal"
      />
    </div> -->
     <div class="footer">
      <base-button
        :label="sealType==='0'?'鲜章盖章下载':'自动发送签章'"
        @click="confirmSeal"
      />
    </div>
    <!-- 列表查看预览文件 -->
    <preview-dialog
      :previsible.sync="previsible"
      :fileId="fileId"
      :dialogType="'list'"
      :fileType="fileType"
    ></preview-dialog>
    <!-- 文件盖章序 -->
    <seal-order-dialog
      :serlOrderVisible.sync="serlOrderVisible"
    ></seal-order-dialog>
    <!-- 文件信息弹窗 -->
    <sign-file-details
      :fileInfovisible.sync="fileInfovisible"
    ></sign-file-details>
  </div>
</template>
<script>
import backtitle from '@/pages/business/components/backtitle.vue'
import SignHeader from '../../components/sign-header.vue'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { dataTable } from '../utils/details-config'
import PreviewDialog from '../../components/preview-dialog.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import SealOrderDialog from '../components/seal-order-dailog.vue'
import SignTitle from '../../components/sign-title.vue'
import signFileDetails from '../components/file-info-dailog.vue'
import Storage from '@/utils/storage'
import { seqFilterData, filterData } from '../utils/utils'
import { signApi } from '@/api/businessApi'
import { downFile } from '@/utils/devUtils'
import { downAPi } from '@/api/fileApi'
export default {
  components: {
    backtitle,
    SignHeader,
    BaseTable,
    PreviewDialog,
    IconButton,
    BaseButton,
    SealOrderDialog,
    SignTitle,
    signFileDetails
  },
  data () {
    return {
      role: '',
      summaryData: {}, // 申请签约文件数据
      textLabel: '', // 申请签约文件名称
      tableData: [],
      loadCount: 0,
      previsible: false, // 文件预览
      fileId: '', // 文件id
      fileType: '',
      serlOrderVisible: false, // 文件盖章序弹窗
      fileInfovisible: false, // 文件信息弹窗
      amount: '', // 合同数量
      sealType: ''// 盖章类型
    }
  },
  computed: {
    columns () {
      return dataTable(this)
    },
    api () {
      return signApi
    }
  },
  mounted () {
    // 获取角色信息
    this.role = this.$store.state.user.userInfo.userType
    this.summaryData = Storage.getSession('signData')
    this.sealType = this.summaryData.suppliersSealType
    this.amount = this.summaryData.contractCount.toString()
    this.textLabel = this.role === '22' ? '合同文件' : '待确认文件'
    if (this.sealType === '0') {
      // 鲜章
      this.getSignDocList()
    } else {
      // 电子章
      this.getSignDetail()
    }
  },
  methods: {
    // 获取鲜章盖章文件
    getSignDocList () {
      this.api.getSignDocList({ taskId: this.summaryData.keyId }).then((res) => {
        if (res.data) this.tableData = res.data
      })
    },
    // 获取电子章盖章文件
    getSignDetail () {
      this.api.getSignDetail({ taskId: this.summaryData.keyId }).then((res) => {
        if (res.code === '0') {
          this.tableData = filterData(res.data, 'docName', 'enterpriseIdRowSpan')
        }
      })
    },
    // 获取文件盖章序
    getTaskDocSeq () {
      const params = {
        taskId: this.summaryData.keyId
      }
      this.api.getTaskDocSeq(params).then((res) => {
        this.orderTableData[0] = seqFilterData(res.data.seqDocs, 'seq', 'seqRowSpan')
        this.orderTableData[1] = filterData(res.data.noSeqDocs, 'docName', 'docNameRowSpan')
        this.count++
      })
    },
    // 展示预览
    showPreview (data) {
      this.fileId = data.currentFileId ? data.currentFileId : ''
      const fileSuffix = data.fileName ? data.fileName.split('.') : []
      this.fileType = fileSuffix[fileSuffix.length - 1]
      this.previsible = true
    },
    //
    confirmSeal () {
      if (this.sealType === '0') {
        alert('鲜章')
      } else if (this.sealType === '1') {
        alert('电子章')
      }
    },
    // 批量下载
    downAll () {
      const filtKeyIds = this.tableData.map((item) => {
        return item.currentFileId
      })
      const newFiltKeyIds = [...(new Set(filtKeyIds))]
      if (filtKeyIds.length > 0 && newFiltKeyIds[0]) {
        downAPi
          .batchDownload({ keyIds: newFiltKeyIds, fileName: '合同文件' })
          .then((res) => {
            downFile(res)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    },
    // 文件盖章序弹窗
    showSealOrder () {
      this.serlOrderVisible = true
    },
    // 文件信息
    showInfo () {
      this.fileInfovisible = true
    }
  }

}
</script>
<style lang="scss" src='../index.scss' scoped/>
